<template>
  <div
    v-if="showLoading"
    class="loading-spinner"
  >
    <svg
      class="loading-spinner__icon"
      viewBox="0 0 70 70"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(1.32,0,0,1.32,-4.6,-4.6)">
        <circle
          cx="30"
          cy="30"
          r="25"
        />
      </g>
      <g transform="matrix(1,0,0,1,5,5)">
        <path d="M30,5C17.72,5.355 5.002,14.942 5,30" />
      </g>
    </svg>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';

const store = useStore();

const { isLoading } = useLoadingIndicator({
  throttle: 50,
});

// The spinner can be triggered for async-requestby using the custom hooks!
const isCustomLoading = ref(false);

const nuxt = useNuxtApp();
nuxt.hook('egs:loading:start', () => {
  isCustomLoading.value = true;
});

nuxt.hook('egs:loading:end', () => {
  isCustomLoading.value = false;
});

const showLoading = computed(() => {
  return (isLoading.value || isCustomLoading.value) && !store.getters['backdrop/isActive'];
});
</script>

<style lang="scss" scoped>
@import 'assets/base';

@keyframes loading {
  100% {
    transform: rotate(360deg) translateZ(0);
  }
}

.loading-spinner {
  background-color: rgba(color(white), 0.5);
  display: flex;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 7;
  pointer-events: auto;
  justify-content: center;
  align-items: center;

  &__icon {
    animation: loading 1.25s linear infinite;
    height: 55px;
    width: 55px;
    stroke: color(grey, 13500);
    fill: none;
    stroke-linecap: round;
    stroke-width: 3px;
    overflow: visible;
  }
}
</style>
